<template>
  <p></p>
</template>

<script>
export default {
    name: 'mainPage'
}
</script>

<style>

</style>