<template>
<navbar/>
  <router-view>

  </router-view>
</template>

<script>
import navbar from './vue/components/navbar.vue';

export default {
  name: 'App',
  components: {
    navbar
  },
  methods: {
    back() {
      this.$router.push('/');
    }
  }
}
</script>

<style>
</style>