<template>
  <nav class="navbar">
    <div class="navbar__container">
      <div class="brand-title"><a>Anglická škola</a></div>
      <a class="toggle-button" @click="toggleNavbar">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </a>
      <div class="navbar-links">
        <ul>
          <li><a href="#">Domů</a></li>
          <li><a href="#">Kurzy</a></li>
          <li><a href="#">Nahrávky</a></li>
          <li><a href="#">Kontakt</a></li>
        </ul>
      </div>
        <div class="themeSwith-div">
            <themeSwitch/>
        </div>
    </div>
  </nav>
</template>

<script>
import themeSwitch from './themeSwitch.vue'
export default {
    name: 'NavigationBar',
    components: {
        themeSwitch
    },
    prop: {
        redirect: String
    },
    methods: {
        toggleNavbar() {
            const navbarLinks = document.getElementsByClassName('navbar-links')[0];
            navbarLinks.classList.toggle('active');
            const themeSwitch = document.getElementsByClassName('themeSwith-div')[0];
            themeSwitch.classList.toggle('active');
        }
    }
}
</script>

<style lang="scss">
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--navbar-background-color);
}
.navbar__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 1300px;
    font-size: 1.2em;
    .brand-title {
        font-size: 1.25em;
    }
    .navbar-links {
        transition: all 0.3s ease;
        ul {
            display: flex;
            li {
                list-style: none;
                &:hover {
                    background-color: var(--navbar-links-hover);
                    transition: all 0.3s ease;
                }
                a {
                    display: block;
                    text-decoration: none;
                    padding: 1rem;
                    font-weight: 300;
                }
            }
        }
    }
}
.navbar-links ul {
    gap: 2rem;
}

.toggle-button {
    position: absolute;
    top: 20px;
    right: 15px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    .bar {
        height: 3.5px;
        width: 100%;
        background-color: var(--font-color);
        border-radius: 10px;
    }
}
@media screen and (max-width: 840px) {
    .navbar__container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
    }

    .navbar__container {
        width: 100%;
        min-height: 50px;
    }

    .toggle-button {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
        margin-bottom: 1rem;
        ul {
            // flex-direction: column;
            display: flex;
            width: 100%;
            li {
                text-align: center;
                margin: 0;
                a {
                    padding: 2rem 1rem;
                }
            }
        }
    }
    .navbar-links ul {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }
    .navbar-links.active {
        display: flex;
    }
    .themeSwith-div {
        display: none;
    }
    .themeSwith-div.active {
        display: flex;
    }
}

</style>